import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const PressPage: React.FC = () => (
    <Layout>
        <SEO title="Press" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">Press</h1>

                <p>
                    For press inquiries and partnership, see the{" "}
                    <Link to="/contact/">contact page</Link>.
                </p>
            </div>
        </section>
    </Layout>
);

export default PressPage;
